<template>
  <div class="focus">
    <van-swipe :autoplay="3000" class="my-swipe"  @change="onChange">
      <van-swipe-item class="swipe-item" v-for="(newItem, index) in newList" :key="index">
        <a :href="newItem.linkurl">
          <van-image
            class="swipeimg"
            height="30rem"
            width="100%"
            fit="cover"
            :src="newItem.picture"
          />
         <p class="title">{{decodeURIComponent(newItem.title.replace(/\+/g, "%20"))}}</p>
        </a>
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">
           <ul>
            <li v-for="(newItem, index) in newList" :key="'indicator'+index" :class="[current==index?'current':'']">
              
            </li>
           </ul>
        </div>
      </template>
    </van-swipe>
  </div>
</template>
<script>
export default {
  name: "FocusNew",
  data() {
    return {
      current: 0,
      newList: []
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    loadData: function() {
      var that = this;
      var params = { top: 6 };
      that.$api.getFocusnew(params).then(res => {
        that.newList = res.data;
      });
    }
  }
};
</script>
<style>
.focus{
  padding: 1rem;
  background: #fff;
  border-radius: .5rem;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 20rem;
  text-align: center;
  background-color: #39a9ed;
  height: 30rem;
}
.swipe-item a {
  width: 100%;
  position: relative;
  height: 30rem;
  display: block;
  line-height: 30rem;
}
.swipe-item .swipeimg {
  width: 100%;
  height: 30rem;
}

.swipe-item a p {
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  color: #fff;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  margin: 0;
  padding: 0;
  font-size: 2.2rem;
  text-align: left;
  padding-left: 1rem;
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
}
.custom-indicator ul{ display: flex;}
.custom-indicator ul li{height: .5rem; width:1rem ; margin: 0 .3rem; background: #d5d5d5; border-radius: .25rem;}
.custom-indicator ul li.current{ width: 2rem; background: #fff;}
</style>